import React from 'react'
// import { Container } from './Container'
// import { Reddit } from '../Icon/Reddit'
// import { Github } from '../Icon/Github'
// import { Twitter } from '../Icon/Twitter'
// import { Discord } from '../Icon/Discord'

import './BigFooter.css'

// const year = Math.max(new Date().getFullYear(), 2020)

export const BigFooter = () => {
  // let versions = injectVersions({})

  return (
    <div>
    </div>
  )
}

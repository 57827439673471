import { fetchApi, Request_Method } from "./login";

export const getFeatureConfig = () => {
    return fetchApi({
        url: '/api/v1/kernel/config?dataId=feature_flags',
        method: Request_Method.GET
    })
}

export const getKernelRendererConfig = () => {
    return fetchApi({
        url:'/api/v1/kernel/config?dataId=owe_config',
        method: Request_Method.GET
    })
}
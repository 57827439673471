// import { trackConnectWallet } from 'decentraland-dapps/dist/modules/analytics/utils'
// import { disconnect, getEthereumProvider, restoreConnection } from '../eth/provider'
import { disconnect, getEthereumProvider } from '../eth/provider'
import { checkPhone } from '../apis/login'
// import { internalTrackEvent, identifyUser, trackError, disableAnalytics } from '../integration/analytics'
import { injectKernel } from './injector'
import {
  setKernelAccountState,
  setKernelError,
  setRendererLoading,
  setKernelLoaded,
  setRendererReady
} from '../state/actions'
import { ErrorType, store } from '../state/redux'
import { ChainId } from '@dcl/schemas/dist/dapps/chain-id'
import { ProviderType } from '@dcl/schemas/dist/dapps/provider-type'
import { resolveUrlFromUrn } from '@dcl/urn-resolver'
import { defaultWebsiteErrorTracker } from '../utils/tracking'
import { KernelResult } from '@dcl/kernel-interface'
import { ENV, NETWORK } from '../integration/url'
import { RequestManager } from 'eth-connect'
// import { errorToString } from '../utils/errorToString'
import { isElectron } from '../integration/desktop'
import { setAsRecentlyLoggedIn } from '../integration/browser'
import { getKernelRendererConfig } from '../apis/config'

// this function exists because decentraland-connect seems to return
// invalid or cached values in chainId, ignoring network changes in the
// real provider.
async function getChainIdFromProvider (provider: any) {
  const rm = new RequestManager(provider)
  return parseInt(await rm.net_version(), 10)
}

function getWantedChainId () {
  // let chainId = ChainId.ETHEREUM_MAINNET // mainnet
  // let chainId = 123
  let chainId = Number(process.env.REACT_APP_CHAIN_ID)
  if (NETWORK === 'goerli') {
    chainId = ChainId.ETHEREUM_GOERLI
  }

  return chainId
}

export async function authenticate (providerType: ProviderType | null) {
  try {
    const wantedChainId = getWantedChainId()
    // const wantedChainId = 123

    // const { provider, chainId: providerChainId, account } = await getEthereumProvider(providerType, wantedChainId)
    const { provider, chainId: providerChainId } = await getEthereumProvider(providerType, wantedChainId)

    if (providerChainId !== wantedChainId) {
      store.dispatch(
        setKernelError({
          error: new Error(
            `Network mismatch NETWORK url param is not equal to the provided by Ethereum Provider (wanted: ${wantedChainId} actual: ${providerChainId}, E01)`
          ),
          code: ErrorType.NET_MISMATCH,
          extra: {
            providerChainId: providerChainId,
            wantedChainId: wantedChainId
          }
        })
      )
      return
    }

    {
      const providerChainId = await getChainIdFromProvider(provider)
      if (providerChainId !== wantedChainId) {
        store.dispatch(
          setKernelError({
            error: new Error(
              `Network mismatch NETWORK url param is not equal to the provided by Ethereum Provider (wanted: ${wantedChainId} actual: ${providerChainId}, E02)`
            ),
            code: ErrorType.NET_MISMATCH,
            extra: {
              providerChainId: providerChainId,
              wantedChainId: wantedChainId
            }
          })
        )
        return
      }
    }

    const kernel = store.getState().kernel.kernel

    if (!kernel) throw new Error('Kernel did not load yet')

    setAsRecentlyLoggedIn()

    kernel.authenticate(provider, providerType == null /* isGuest */)

    // Track that the users wallet has connected.
    // Only when the user has not connected as guest.
    // if (providerType && account) {
    //   trackConnectWallet({ providerType, address: account })
    // }
  } catch (err) {
    if (
      err &&
      typeof err === 'object' &&
      ['Fortmatic: User denied account access.', 'The user rejected the request.'].includes(err.message)
    ) {
      return
    }

    if (
      err &&
      typeof err === 'object' &&
      typeof err.message == 'string' &&
      (err.message.includes('Already processing eth_requestAccounts.') || err.message.includes('Please wait.'))
    ) {
      // https://github.com/decentraland/explorer-website/issues/46
      store.dispatch(
        setKernelError({
          error: new Error('Metamask is locked, please open the extension before continuing.'),
          code: ErrorType.METAMASK_LOCKED
        })
      )
      return
    }

    // If something went wrong, disconnect to prevent future errors next reload
    disconnect().catch(defaultWebsiteErrorTracker)

    defaultWebsiteErrorTracker(err)

    store.dispatch(
      setKernelError({
        error: err
      })
    )
  }
}

type RolloutRecord = {
  version: string
  percentage: number
  prefix: string
}

declare var globalThis: {
  KERNEL_BASE_URL?: string
  RENDERER_BASE_URL?: string
  ROLLOUTS?: Record<string, RolloutRecord>
}
export const RENDERER_TYPE = window.location.href.includes('ws')
globalThis.KERNEL_BASE_URL = process.env.REACT_APP_KERNEL_BASE_URL
globalThis.RENDERER_BASE_URL = process.env.REACT_APP_RENDERER_BASE_URL

async function resolveBaseUrl (urn: string): Promise<string> {
  if (urn.startsWith('urn:')) {
    const t = await resolveUrlFromUrn(urn)
    if (t) {
      return (t + '/').replace(/(\/)+$/, '/')
    }
    throw new Error('Cannot resolve content for URN ' + urn)
  }
  return (new URL(`${urn}`, global.location.toString()).toString() + '/').replace(/(\/)+$/, '/')
}


async function initKernel () {
  const container = document.getElementById('gameContainer') as HTMLDivElement
  let data;
  try {
    data = (await getKernelRendererConfig()).data
    const name = window.location.pathname.slice(1)
    if(process.env.NODE_ENV !== 'development'){
      globalThis.KERNEL_BASE_URL = data['kernel_base_url_' + name] || data['kernel_base_url'] || globalThis.KERNEL_BASE_URL
      globalThis.RENDERER_BASE_URL = data['renderer_base_url_' + name] || data['renderer_base_url'] || globalThis.RENDERER_BASE_URL
    }
    
  } catch (error) {
    console.log(error)
  }
  
  const kernel = await injectKernel({
    kernelOptions: {
      baseUrl: await resolveBaseUrl(globalThis.KERNEL_BASE_URL || `urn:decentraland:off-chain:kernel-cdn:latest`),
      configurations: {}
    },
    rendererOptions: {
      container,
      baseUrl: await resolveBaseUrl(
        globalThis.RENDERER_BASE_URL || `urn:decentraland:off-chain:unity-renderer-cdn:latest`
      )
    }
  })

  kernel.on('openUrl', ({ url }) => {
    try {
      const u = new URL(url)
      const newWindow = window.open(u.toString(), '_blank', 'noopener,noreferrer')
      if (newWindow != null) newWindow.opener = null
    } catch (err: any) {
      // trackError(err, { context: 'explorer-website' })
      console.log(err)
    }
  })


  kernel.on('accountState', account => {

    store.dispatch(setKernelAccountState(account))
  })

  // all errors are also sent as trackingEvent
  kernel.on('error', error => {
    store.dispatch(setKernelError(error))
    console.log(error)
  })

  kernel.on('rendererVisible', event => {
    store.dispatch(setRendererReady(event.visible))

    if (event.visible) {
      store.dispatch(setKernelError(null))
    }
  })

  kernel.on('loadingProgress', event => {
    store.dispatch(setRendererLoading(event))
  })

  kernel.on('logout', () => {
    disconnect().catch(defaultWebsiteErrorTracker)
  })

  return kernel
}

async function initLogin (kernel: KernelResult) {
  if (!isElectron()) {
    // const provider = await restoreConnection()
    // if (provider && provider.account) {
    //   const providerChainId = await getChainIdFromProvider(provider.provider)

    //   // BUG OF decentraland-connect:
    //   // provider.chainId DOES NOT reflect the selected chain in the real provider
    //   const storedSession = await kernel.hasStoredSession(provider.account, providerChainId /* provider.chainId */)
    //   if (storedSession) {
    //     track('automatic_relogin', { provider_type: provider.providerType })
    //     authenticate(provider.providerType).catch(defaultWebsiteErrorTracker)
    //   }
    // }
    //现在的修改
    const loginInfo: any = window.localStorage.getItem('loginInfo')
    const token = JSON.parse(loginInfo) && JSON.parse(loginInfo).token
    const phone = JSON.parse(loginInfo)?.phone
    const isAutoLogin = window.localStorage.getItem('autoLogin')
    if (token) {
      let isCheck = await checkPhoneChange(phone)
      if (isCheck) {
        const wantedChainId = getWantedChainId()
        const {
          provider,
          chainId: providerChainId,
          account
        } = await getEthereumProvider(ProviderType.INJECTED, wantedChainId)
        if (provider && account) {
          const storedSession = await kernel.hasStoredSession(account, providerChainId /* provider.chainId */)
          if (storedSession && isAutoLogin === 'true') {
            authenticate(ProviderType.INJECTED)
          }
        }
      } else {
        window.localStorage.removeItem('loginInfo')
      }
    }
  }
}
async function checkPhoneChange (phoneNumber: string) {
  try {
    if (phoneNumber) {
      const res = await checkPhone({ phone: phoneNumber })
      if (res.code !== 200) {
        return false
      }
      return true
    }
  } catch (e) {
    console.log('e==', e)
    return false
  } finally {
  }
}

export function startKernel () {
  if (NETWORK && NETWORK !== 'mainnet' && NETWORK !== 'goerli') {
    store.dispatch(
      setKernelError({
        error: new Error(`Invalid NETWORK url param, valid options are 'goerli' and 'mainnet'`),
        code: ErrorType.FATAL
      })
    )
    return
  }

  if (ENV) {
    store.dispatch(
      setKernelError({
        error: new Error(
          `The "ENV" URL parameter is no longer supported. Please use NETWORK=goerli in the cases where ENV=zone was used`
        ),
        code: ErrorType.FATAL
      })
    )
    return
  }

  if (isElectron()) {
    if ((window as any).require) {
      store.dispatch(
        setKernelError({
          error: new Error(
            `You're using an old version of Decentraland Desktop. Please update it from https://github.com/decentraland/explorer-desktop-launcher/releases`
          ),
          code: ErrorType.FATAL
        })
      )
      return
    }
  }

  return initKernel()
    .then(kernel => {
      store.dispatch(setKernelLoaded(kernel))
      return initLogin(kernel)
    })
    .catch(error => {
      store.dispatch(setKernelError({ error }))
      defaultWebsiteErrorTracker(error)
    })
}

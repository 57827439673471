import React, { useMemo, useRef  } from 'react'
import { LoginState } from '@dcl/kernel-interface'
import { ProviderType } from '@dcl/schemas/dist/dapps/provider-type'
import { toFeatureList } from '@dcl/feature-flags'
import { connect } from 'react-redux'
import { connection } from 'decentraland-connect/dist/index'
import { StoreType } from '../../state/redux'
import { FeatureFlags, getFeatureVariantName, VariantNames } from '../../state/selectors'
import { authenticate } from '../../kernel-loader'
import { isElectron } from '../../integration/desktop'
import { disconnect } from '../../eth/provider'
import './LoginContainer.css'
import { LoginAccount } from './LoginItemContainer'

export const defaultAvailableProviders = []

const mapStateToProps = (state: StoreType): LoginContainerProps => {
  // test all connectors
  const enableProviders = new Set([ProviderType.INJECTED, ProviderType.FORTMATIC, ProviderType.WALLET_CONNECT])
  const availableProviders = connection.getAvailableProviders().filter(provider => enableProviders.has(provider))
  return {
    availableProviders,
    stage: state.session.kernelState?.loginStatus,
    provider: state.session.connection?.providerType,
    kernelReady: state.kernel.ready,
    rendererReady: state.renderer.ready,
    isGuest: state.session.kernelState ? state.session.kernelState.isGuest : undefined,
    isWallet: state.session.kernelState ? !state.session.kernelState.isGuest && !!state.session.connection : undefined,
    isSignInFlowV3: getFeatureVariantName(state, FeatureFlags.SignInFlowV3) === VariantNames.New && !isElectron(),
    featureList: toFeatureList(state.featureFlags)
  }
}

enum TrackingActionType {
  SignIn = 'sign_in',
  CreateAccount = 'create_account'
}

const mapDispatchToProps = (dispatch: any) => ({
  onLogin: (providerType: ProviderType | null, action_type?: TrackingActionType) => {
    authenticate(providerType)
  },
  onCancelLogin: () => {
    disconnect().then(() => window.location.reload())
  }
})

export interface LoginContainerProps {
  stage?: LoginState
  provider?: ProviderType
  availableProviders?: ProviderType[]
  kernelReady: boolean
  rendererReady: boolean
  isGuest?: boolean
  phoneNumber?: string
  verificationCode?: string
  isChecked?: boolean
  isWallet?: boolean
  isSignInFlowV3: boolean
  featureList: string[]
}

export interface LoginContainerDispatch {
  onLogin: (provider: ProviderType | null, action_type?: TrackingActionType) => void
}

export const LoginContainer: React.FC<LoginContainerProps & LoginContainerDispatch> = ({
  onLogin,
  stage,
  isGuest,
  kernelReady,
  featureList
}) => {
  
  let child = useRef<null | { accountLogin?: any }>()

  const loading = useMemo(() => {
    return (
      stage === LoginState.SIGNATURE_PENDING ||
      stage === LoginState.WAITING_PROFILE ||
      stage === LoginState.WAITING_RENDERER ||
      stage === LoginState.LOADING ||
      !kernelReady
    )
  }, [stage, kernelReady])


  if (stage === LoginState.COMPLETED) {
    return <React.Fragment />
  }

  return (
    <div className='home-content'>
      <div className='home-first-content'>
        <div className='logo'></div>
        <div className='home-first-login'>
          <LoginAccount
            loading={loading}
            active={isGuest}
            ref={child}
            authenticate={() => {
              authenticate(ProviderType.INJECTED)
            }}
          />
        </div>
        <div className='bg'></div>
        <div className='bg2'></div>
      </div>
    </div>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)

import React, { useState, useRef, useImperativeHandle, forwardRef, useCallback } from 'react'
import { ProviderType } from '@dcl/schemas/dist/dapps/provider-type'
import { Button } from 'decentraland-ui/dist/components/Button/Button'
import { Loader } from 'decentraland-ui/dist/components/Loader/Loader'
import { getCaptcha, loginInfo } from '../../apis/login'
import { NoticeAgreement } from './NoticeAgreement'
import CorrectCircle from '../../imagesData/correct_circle.svg'
import './LoginItemContainer.css'

let loginType = false

export type LoginItemContainerProps = {
  onClick?: () => void
  onClickGuest?: () => void
  onGetverificationCode?: () => {}
  accountLogin?: () => void
  onCancelLogin?: () => void
  phoneChange?: () => void
  getChecked?: () => void
  authenticate?: () => void
  closeDialog?: () => void
  className?: string
  isChecked?: boolean
  phoneNumber?: string
  verificationCode?: string
  loading?: boolean
  canceling?: boolean
  active?: boolean
  provider?: ProviderType
  children?: React.ReactNode
}

export const LoginAccount = forwardRef((props: LoginItemContainerProps, ref: any) => {
  const [phoneNumber, setText] = useState<string>('')
  const [isCheckedLogin, setIsCheckedLogin] = useState<boolean>(false)
  const phoneNumberChangeHandler = (event: any) => {
    setText(event.target.value)
  }
  const veriNumberChangeHandler = (event: any) => {
    setVerificationCode(event.target.value)
  }
  useImperativeHandle(ref, () => ({
    accountLogin: accountLogin
  }))

  const [verificationCode, setVerificationCode] = useState<string>('')
  const [isShowToast, setIsShowToast] = useState<boolean>(false)
  const [toastInfor, setToasInfor] = useState<string>('')
  const [codeBtnWord, setCodeBtnWord] = useState<string>('获取验证码')

  const waitTime = useRef<number>(61)
  const onGetverificationCode = () => {
    if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(phoneNumber)) {
      toastInforData('手机号格式不正确')
      return false
    }
    getVerificationCode()
  }
  // 获取验证码
  const getVerificationCode = async () => {
    if (codeBtnWord !== '获取验证码') {
      return false
    }

    if (!phoneNumber) {
      toastInforData('请输入手机号码')
      return false
    }

    const res = await getCaptcha({ phone: phoneNumber })

    if (res.code === 200) {
      toastInforData('验证码发送成功')
      timeData()
    } else {
      console.log('其他-=-=', res)
      toastInforData(res.message)
    }
  }
  const timeData = () => {
    waitTime.current -= 1
    setCodeBtnWord(`${waitTime.current}s后重新获取`)
    const timer = setInterval(() => {
      if (waitTime.current > 1) {
        waitTime.current -= 1
        setCodeBtnWord(`${waitTime.current}s后重新获取`)
      } else {
        clearInterval(timer)
        setCodeBtnWord('获取验证码')
        waitTime.current = 61
      }
    }, 1000)
  }
  const accountLogin = async () => {
    if (!isCheckedLogin) {
      toastInforData('请勾选我已阅读并同意服务协议及隐私政策')
      return false
    }
    loginType = true
    window.event?.stopPropagation()
    const canSubmit = doValidate()
    if (!canSubmit) return
    const res = await loginInfo({
      phone: phoneNumber,
      captcha: verificationCode,
      loginType: '1'
    })
    if (res.code === 200) {
      toastInforData('登录成功')
      const objData = Object.assign({}, res.data, { phone: phoneNumber })
      window.localStorage.setItem('loginInfo', JSON.stringify(objData))
      props.authenticate && props.authenticate()
    } else {
      toastInforData(res.message)
    }
  }
  const toastInforData = (infor: string) => {
    setIsShowToast(true)
    setToasInfor(infor)
    setTimeout(() => {
      setIsShowToast(false)
    }, 1000)
  }
  const doValidate = () => {
    if (!phoneNumber) {
      toastInforData('请输入手机号码')
      return false
    } else if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(phoneNumber)) {
      toastInforData('手机号格式不正确')
      return false
    }
    if (!verificationCode) {
      toastInforData('请输入验证码')
      return false
    }
    return true
  }
  const loginSelectEvent = () => {
    isCheckedLogin ? setIsCheckedLogin(false) : setIsCheckedLogin(true)
  }

  const [isAutoLogin, setAutoLogin] = useState(false)
  const changeAutoLogin = useCallback(() => {
    window.localStorage.setItem('autoLogin', String(!isAutoLogin))
    setAutoLogin(!isAutoLogin)
  }, [isAutoLogin, setAutoLogin])

  return (
    <div className="dialog-data-content">
      <div className="data-content">
        <div className="verification-code">欢迎登录稀元数创</div>
        <div className="phone-data input-container">
          <span className="person-icon"></span>
          <input placeholder="请输入手机号" value={phoneNumber} onChange={phoneNumberChangeHandler} />
        </div>
        <div className="code-number">
          <div className="code-input input-container">
            <span className="code-icon"></span>
            <input placeholder="验证码" value={verificationCode} onChange={veriNumberChangeHandler} />
            {verificationCode.length > 0 ? <img className="correct-circle" src={CorrectCircle} alt="" /> : <></>}
          </div>
          <div className="get-code" onClick={onGetverificationCode}>
            {codeBtnWord}
          </div>
        </div>
        <div className="auto-login">
          <div className={isAutoLogin ? 'auto-login-checked' : 'auto-login-notChecked'} onClick={changeAutoLogin}></div>
          <span className={isAutoLogin ? 'checked-status' : 'notChecked-status'}>自动登录</span>
        </div>
        <div className="login-btn">
          <Button onClick={accountLogin} loading={props.loading && !props.active} disabled={props.loading}>
            登 录
          </Button>
        </div>
        <NoticeAgreement isChecked={isCheckedLogin} selectedEvent={loginSelectEvent} />
        {props.loading && !props.active && loginType && (
          <div className="loader-background">
            <Loader active={props.loading && !props.active && loginType} provider={props.provider} size="massive" />
          </div>
        )}
      </div>
      {isShowToast && <div className="login-toast">{toastInfor}</div>}
    </div>
  )
})

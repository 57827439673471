import React from 'react'
import './LoadingRender.css'

export const LoadingRender = React.memo(function () {
  return (
    <div className='LoadingRender'>
      <div className='coming-soon-action'>
        <div className='loading-logo'></div>
      </div>
      <div className='coming-blank'></div>
      <div className='coming-loading-word'>即将进入稀元数创，请稍候...</div>
    </div>
  )
})

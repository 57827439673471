
import qs from 'qs'

export enum Request_Method {
    GET = 'get',
    POST = 'post'
}

interface FetchProps {
    url:string
    method:Request_Method
    query?:Record<string,any>
    body?:any
}

// post 请求
export const fetchApi = async ({url, method, query, body}:FetchProps)=>{
    let str:string
    if(query){
        str = qs.stringify(query)
        url = `${url}?${str}`
    }
    const loginInfo: any = window.localStorage.getItem('loginInfo')
    const token = loginInfo?(JSON.parse(loginInfo)).token : ""
    return fetch(url, {
        method,
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": 'Bearer ' + token
        },
        body: body?JSON.stringify(body) : undefined,
        mode: "cors", //指定请求模式，此时为cors表示支持跨域请求
    }).then(res=>{
        console.log('res===',res)
        return res.json()
    })
}

export const getCaptcha = (body:any) => {
    return fetchApi({
        url: '/api/v1/base/captcha', 
        method: Request_Method.POST, 
        body
    })
}

export const loginInfo = (body:any) => {
    return fetchApi({
        url: '/api/v1/base/login', 
        method: Request_Method.POST, 
        body
    })
}

export const checkPhone = (query:any) => {
    return fetchApi({
        url: `/api/v1/base/ver_token`, 
        method: Request_Method.GET, 
        query
    })
}
import React from 'react'
import './NoticeAgreement.css'
export type enterProps = {
    isChecked?: boolean,
    selectedEvent?: ()=> viod
  }
export const NoticeAgreement = React.memo(({ isChecked,selectedEvent}:enterProps) => {
    return (
        <div className='notice-agreement-wrap'>
            <div className={isChecked ? 'notice-agreement-checked' : 'notice-agreement-notChecked'} onClick={selectedEvent}></div>
            <div className='notice-agreement-data'>
            我已阅读并同意<a href='https://xraremeta.com/privacy/#/agreement' target="view_window"><span></span>《服务协议》</a>及<a href='https://xraremeta.com/privacy/#/policy' target="view_window">《隐私政策》</a>
            </div>
        </div>
    )
})
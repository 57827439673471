import React from 'react'
import errorImage from '../../images/errors/error-robotmobile.png'
import { ErrorContainer, ErrorDetails, ErrorImage } from './Error'
import './errors.css'

export const ErrorNotSupported = React.memo(function () {
  return <ErrorContainer id="error-new-login">
    <ErrorDetails
      backgroundHeader="Error"
      header="系统不支持您的浏览器或设备"
      description="只支持在Windows，Linux和Mac OS的谷歌浏览器或者火狐浏览器上运行"
      />
    <ErrorImage alt="error-mobile-robot" src={errorImage} />
  </ErrorContainer>
})
